import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
let NoAccountModal = class NoAccountModal extends AbstractModal {
    toRegisterModal() {
        this.swapModal(EModal.Welcome);
    }
};
NoAccountModal = __decorate([
    Component({ name: 'NoAccountModal' })
], NoAccountModal);
export { NoAccountModal };
export default NoAccountModal;
